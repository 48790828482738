<script>

    import { push } from 'svelte-spa-router';


    export let currentFilter = 'Laisvi laikai'; // Filter state passed from parent
</script>


<div class="filter-buttons">
    <!-- Button for 'Laisvi laikai' with conditional class 'selected' -->
    <button 
        class:selected={currentFilter === 'Laisvi laikai'} 
        on:click={() => push('/settingsAndTimeslots')}>
        Laisvi laikai
    </button>
    
    <!-- Button for 'Sąskaitos' with conditional class 'selected' -->
    <button 
        class:selected={currentFilter === 'Sąskaitos'} 
        on:click={() => push('/invoice')}>
        Sąskaitos
    </button>

    <!-- Button for 'Paskyra' with conditional class 'selected' -->
    <button 
        class:selected={currentFilter === 'Paskyra'} 
        on:click={() => push('/account')}>
        Paskyra
    </button>
</div>

<style>
    .filter-buttons {
        display: flex;
        gap: 1rem;
        height: 60px;
        justify-content: space-between;
    }

    button { 
        border-radius: 8px;
        background-color: white;
        color: #000000;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        width:100%;
    }

    .selected {
        background-color: #14695b; /* Change to the color you want */
        color: white;
    }

    button:hover {
        background-color: #14695b;
        color: white;
    }
</style>
