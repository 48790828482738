<script>
    export let label = '';
    export let options = [];
    export let selectedValue = '';
    export let onChange = null;

    const handleChange = (event) => {
        selectedValue = event.target.value;
        if (onChange && typeof onChange === 'function') {
            onChange(selectedValue);
        }
    };
</script>

<div class="dropdown">
    <label for="dropdown">{label}</label>
    <select id="dropdown" bind:value={selectedValue} on:change={handleChange}>
        <option disabled value="">Pasirinkite...</option>
        {#each options as [value, text]}
            <option value={value}>{text}</option>
        {/each}
    </select>
</div>

<style>
    .dropdown {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        width:100%;
    }
    option {
        color: black; /* Ensure options text is black as well */
    }
    label {
        margin-bottom: 0.5rem;
        font-weight: bold;
        font-size:1.17rem;
    }

    select {
        padding: 1rem;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    }

    select:focus {
        outline: none;
        border-color: #007bff;
    }
</style>
