<!-- App.svelte -->
<script>
	import Router from 'svelte-spa-router';
	import Home from './Home.svelte';
	import AboutPage from './AboutPage.svelte';
	import LogInGreeting from './LogInGreeting.svelte';
	import RegisterForClass from './RegistrationForClass.svelte'
	import RegisterSuccess from './RegistrationSucess.svelte'
	import SchedulePage from './Schedule.svelte';
	import './styles/global.css';
	import ChangeClassPage from './ChangeClassTime.svelte';
	import CancelPage from './CancelClassPage.svelte';
	import ChangeSucess from './RescheduleSuccess.svelte'
	import LeaveCommentPage from './LeaveCommentPage.svelte'
	import '@fortawesome/fontawesome-free/css/all.min.css';
	import LeaveNoteSuccess from './LeaveNoteSuccess.svelte';
	import RescheduleRequestsPage from './RescheduleRequestsPage.svelte';
	import SettingsAndTimeslots from './SettingsAndTimeslotsPage.svelte';
	import InvoicesPage from './InvoicesPage.svelte';
	import AccountPage from './AccountPage.svelte';
	import TeacherRequestsPage from './TeacherRequestsPage.svelte';
	import AdminPage from './AdminPage.svelte';
	import UpdateCredentialsPage from './UpdateCredentialsPage.svelte';
  
  
  
  
	const routes = {
		'/': AboutPage,
		'/about': AboutPage,
		'/login-greeting': LogInGreeting,
		'/registration-for-class': RegisterForClass,
		'/register-success':RegisterSuccess,
		'/schedule':SchedulePage,
		'/class/:id': ChangeClassPage,
		'/class/cancel-class/:id':CancelPage,
		'/change-class-success': ChangeSucess,
		'/leave-comment/:id' : LeaveCommentPage,
		'/note-success':LeaveNoteSuccess,
		'/reschedule-requests': RescheduleRequestsPage,
		'/settingsAndTimeslots': SettingsAndTimeslots,
		'/invoice':InvoicesPage,
		'/account':AccountPage,
		'/teacher_requests':TeacherRequestsPage,
		'/admin':AdminPage,
		'/update-credentials':UpdateCredentialsPage,
  
	};
  </script>
  <div class="background"></div>
  <style>
	@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
  
	:global(body) {
	  font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	  
	  color: #14695B;
	}
	.background {
	  position: fixed;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  z-index: -1;
	  background-color: #e3eff1;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	}
	main {
	  padding: 1rem;
	  max-width: 600px;
	  margin: 0 auto;
	}
  </style>
  
  <main>
	<Router {routes} />
  </main>
  