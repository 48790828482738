<script>
    import ClassName from './ClassNameComponent.svelte';
    import TeacherName from './TeacherNameComponent.svelte';
    import TimeShower from './TimeShowerComponent.svelte';
    import SendEmailLink from './RecieveEmailComponent.svelte';
    import ButtonToJoinClass from './ButtonToJoinClassComponent.svelte'
    import { push } from 'svelte-spa-router';
    import { onMount } from 'svelte';
    import ButtonToJoinClassComponent from './ButtonToJoinClassComponent.svelte';
    import FilterButtons from './FilterButtons.svelte'; // Import your component
    import RescheduleButton from './ButtonToRescheduleComponent.svelte'
    import ButtonToComment from './ButtonToLeaveCommentComponent.svelte'

    let events = [];
    let currentFilter = 'all';
    let isUserTeacher = false;
    

    
    // Function to change the link text when clicked
    function changeLinkText(event) {
      const link = event.target;
      link.textContent = "Pamokos informacija išsiųsta!";
    }
    function isPastEvent(eventTime) {
      console.log("THis is the event time RAW");
      console.log(eventTime);
      
      // Split the string at the first occurrence of a space
      const validEventTime = formatEventTime(eventTime)
      
      console.log("Event time without day name:", validEventTime);
      
      const eventDateTime = new Date(validEventTime);
      
      console.log("Edited datetime:", eventDateTime);

      const currentDateTime = new Date();
  
      // Check if the event is in the past
      return eventDateTime < currentDateTime;
  }
    const formatDateTime = (datetime) => {
        let parts = datetime.split(" ");
        let datePart = parts[0];
        let timePart = parts[1];
        let dayPart = parts[2];

        let formattedDate = `${dayPart}<br>${datePart}`;
            return { timePart, formattedDate };
    };
    function formatEventTime(eventTime) {
      // Extract the date and time parts using a regular expression
      const match = eventTime.match(/^(\d{4}-\d{2}-\d{2})\s+(\d{2}:\d{2})/);

      if (!match) {
          console.error("Invalid date format:", eventTime);
          return null; // Return null if the format doesn't match the expected pattern
      }

      const datePart = match[1]; // e.g., "2024-09-06"
      const timePart = match[2]; // e.g., "14:00"

      // Combine the date and time in ISO format, assuming it's in local time
      const formattedTime = `${datePart}T${timePart}:00Z`;

      return formattedTime;
    }

    const fetchUserRole = async () => {
      const token = localStorage.getItem('jwtToken')
      console.log("This is the token:)")
      console.log(token)
      try {
        const response = await fetch('https://www.tutory.app/studentplayground/isUserTeacher/', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.ok) {
          const data = await response.json();
          isUserTeacher = data.isUserTeacher
          console.log("THis is what returns for isUserTeacher:")
          console.log(isUserTeacher)// Assuming the role is returned in the JSON as { "role": "Teacher" }
        } else {
            if (response.status === 401) {
                // If response is 401 Unauthorized, redirect to login
                alert('Reikia prisijungti iš naujo.');
                localStorage.removeItem('jwtToken'); // Optional: Clear any stored tokens
                window.location.href = '#/about'; // Redirect to login page
            } else {
                // Handle other errors
                alert('Error submitting form!');
            }
        }
      } catch (error) {
        console.error("Failed to fetch user role:", error);
        return null;
      }
    };

    const fetchEvents = async () => {
      const token = localStorage.getItem('jwtToken'); // Retrieve token from localStorage

      if (!token) {
        console.error('No JWT token found. User might not be logged in.');
        return;
      }

      try {
        console.log("THis is within the picking, this is Teacher :))")
        console.log(isUserTeacher)
        // Determine the URL based on the user's role
        const url = isUserTeacher
          ? `https://www.tutory.app/teacher_playground/teacherSchedge/?filter=${currentFilter}`
          : `https://www.tutory.app/studentplayground/studentList/?filter=${currentFilter}`;

        console.log("THis is the url we trynna get to:")
        console.log(url)

        // Make the request
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        
        if (response.ok) {
          const data = await response.json();
          events = data.events;
        } else {
            if (response.status === 401) {
                // If response is 401 Unauthorized, redirect to login
                alert('Reikia prisijungti iš naujo.');
                localStorage.removeItem('jwtToken'); // Optional: Clear any stored tokens
                window.location.href = '#/about'; // Redirect to login page
            } else {
                // Handle other errors
                alert('Error submitting form!');
            }
        }
      } catch (error) {
        console.error("Failed to fetch events:", error);
      }
    };


  // Handle filter change event from FilterButtons component
  const handleFilterChange = (event) => {
        currentFilter = event.detail.filter; // Update the filter
        fetchEvents(); // Fetch new events based on the filter
    };

// Fetch events when the component is mounted
onMount(async () => {
  await fetchUserRole();
  console.log("THis is teacher value:D")
  console.log(isUserTeacher)
  fetchEvents();
})
</script>
    
    <style>
      .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background-color: transparent;
        border-bottom: 1px solid #000000;
        margin-bottom: 40px;
      
      }
    
      .filler {
        width:50px;
      }
    
      .label {
        font-size: x-large;
        
      }
    
      .exit-button {
        max-width: 50px;
      }
    
      .icon {
        width: 50px;
        height: 50px;
      }
      .classcontainer{
        padding: 15px;
        background-color: white;
        display: flex;
        flex-direction: column;
        margin-bottom:15px;
        gap: 10px;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        flex: none;
        color:black;
        align-items: flex-start;

      }
      
    </style>
    
    <div class="top-bar">
      
      <a href='#/login-greeting' class="exit-button">
        <img src='/static/icons/backButtonIcon.png' alt="Exit" class="icon" />
      </a>
      <div class="label">Pamokų Tvarkaraštis</div>
      <div class="filler"></div>
    </div>



    <FilterButtons 
        currentFilter={currentFilter} 
        on:filterChange={handleFilterChange} />

        <div class="classSection">
          {#each events as event}
            <div class="classcontainer {isPastEvent(event.laikasDisp) ? 'past-event' : 'upcoming-event'}">
              <!-- Render the class details -->
              <ClassName name={event.pamoka} />
              <TeacherName teacher={event.mokytojas}/>
              <TimeShower 
                time={formatDateTime(event.laikasDisp).timePart} 
                date={formatDateTime(event.laikasDisp).formattedDate} 
              />
    
              {#if isPastEvent(event.laikasDisp)}
                <!-- Render components for past events -->
                
                {#if event.note === 'nėra atsiliepimo' || !event.note}
                    <ButtonToComment classId={event.id}  label="Palikti Atsiliepimą"></ButtonToComment>
                {:else}
                    <ButtonToComment classId={event.id} label="Koreguoti Atsiliepimą" buttonClass="koregouti"></ButtonToComment>
                {/if}
              {:else}
                <!-- Render components for upcoming events -->
                <SendEmailLink {event} />
                <ButtonToJoinClass link={event.nuoroda} label="Jungtis į pamoką" />
                <RescheduleButton classId={event.id} label="Keisti rezervaciją" />
              {/if}
            </div>
        {/each}
    
    </div>
    