<script>
  import CheckBoxAreaForInvoicesComponent from "./CheckBoxAreaForInvoicesComponent.svelte";
  import FilterButtonsForSettingsPage from "./FilterButtonsForSettingsPage.svelte";
  let selectedMonths = [];
  let pdfUrl = ''; // Store the URL of the PDF to display
  let isCreatingPDF = false;
  let email = '';
  let pdfBase64V = '';
  let buttonText = "Išsiųsti laišką į paštą";
  let isSending = false;

  async function handleSubmit(event) {
      event.preventDefault(); // Prevent the default form submit behavior

      if (selectedMonths.length === 0) {
          alert("Please select at least one month.");
          return;
      }

      // Set the button to "Kuriama..." while processing
      isCreatingPDF = true;

      // Call the generateInvoice function
      await generateInvoice(selectedMonths);

      // Set the button back to "Sukurti Sąskaitą" once the process is finished
      isCreatingPDF = false;
  }

  // Function to generate invoice and handle base64 PDF response
  async function generateInvoice(selectedMonths) {
      console.log("Selected months:", selectedMonths);
      const token = localStorage.getItem('jwtToken');
      if (!token) {
          console.error('No JWT token found. User might not be logged in.');
          return;
      }

      try {
          const response = await fetch('https://www.tutory.app/studentplayground/settings/invoices/', {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  months: selectedMonths
              })
          });

          if (!response.ok) {
              throw new Error('Failed to generate the invoice');
          }

          const data = await response.json(); // Parse JSON response
          if (data.status === 'success') {

              email = data.email;
              // Decode the base64 PDF string
              const pdfBase64 = data.pdf_base64;
              pdfBase64V = data.pdf_base64;
              const byteCharacters = atob(pdfBase64); // Convert base64 to binary data
              const byteNumbers = new Array(byteCharacters.length);

              // Convert to an array of bytes
              for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], { type: 'application/pdf' }); // Create a Blob from byteArray

              pdfUrl = URL.createObjectURL(blob); // Create a URL for the PDF Blob
          } else {
              console.error('Error in response:', data);
          }
      } catch (error) {
          console.error('Error generating the invoice:', error);
      }
  }
   // Function to generate invoice and handle base64 PDF response
   async function sendEmail(email) {
        console.log("Selected email:", email);
        const token = localStorage.getItem('jwtToken');
        if (!token) {
            console.error('No JWT token found. User might not be logged in.');
            return;
        }

        // Change button text and disable it
        buttonText = "Sunčiama";
        isSending = true;

        try {
            const response = await fetch('https://www.tutory.app/studentplayground/settings/invoice/view_pdf/send_pdf_email/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    pdf_data: pdfBase64V, // Make sure pdfBase64V is defined and contains the PDF data
                    email: email
                })
            });

            if (!response.ok) {
                throw new Error('Failed to send the invoice');
            }

            const data = await response.json();  // Parse JSON response

            if (data.status === 'success') {
                console.log("Email sent successfully");

                // Show alert after successful email
                alert(`Laiškas išsiųstas į: ${email}`);

                // Reload the page after alert is closed
                location.reload();
            } else {
                console.error('Error in response:', data);
                buttonText = "Išsiųsti laišką į paštą";  // Reset the button text if there's an error
                isSending = false;  // Enable the button again
            }
        } catch (error) {
            console.error('Error sending email', error);
            buttonText = "Išsiųsti laišką į paštą";  // Reset the button text if there's an error
            isSending = false;  // Enable the button again
        }
    }
</script>



<style>
  button span {
       font-weight: bolder;
       font-size:larger;
   }

   button {
      margin-top:10px;
       box-sizing: border-box;
       display: flex;
       flex-direction: row;
       justify-content: center;
       align-items: center;
       padding: 12px 20px;
       gap: 10px;
   
       width: 100%;
       height: 60px;
   
       /* Purple background */
       background-color: white;
   
       /* Black border */
       border: 2px solid #000000;
   
       /* Box shadow */
       box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
   
       /* Rounded corners */
       border-radius: 8px;
   
       /* Inside auto layout */
       flex: none;
       order: 0;
       align-self: stretch;
       flex-grow: 0;
   
       /* Remove default button styling */
       outline: none;
       cursor: pointer;
 
       /* Remove the default button background on focus */
       background-image: none;
       transition: background-color 0.3s ease, color 0.3s ease;
   }

   /* Button hover effect */
   button:hover {
       background-color: #14695b;
       color:white;
   }

   /* Text inside the button */
   span {
      margin-top:20px;
      margin-bottom: 20px;
      font-size: 1rem;
      font-weight: bold;
   
   }
 form {
     padding-bottom: 10px;
     padding-top:15px;
 }
 span {
     font-weight: bold;
 }
 
 label {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
        display: block;
        color:black;
    }
 .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: transparent;
      border-bottom: 1px solid #000000;
      margin-bottom: 40px;
    }
    .filler {
      width: 50px;
    }
  
    .label {
      font-size: x-large;
    }
    
    .icon {
      width: 50px;
      height: 50px;
    }
    input {
      border-radius: 6px;

      width:100%;
    }
</style>
<div class="top-bar">
  <a href='#/login-greeting' class="exit-button">
    <img src='/static/icons/backButtonIcon.png' alt="Exit" class="icon" />
  </a>
  <div class="label">Mano Profilis</div>
  <div class="filler"></div>
</div>
<FilterButtonsForSettingsPage currentFilter='Sąskaitos'></FilterButtonsForSettingsPage>

<!-- Simple form for selecting months -->
{#if !pdfUrl} <!-- Show form if no PDF is available -->
<form on:submit={handleSubmit}>
  <label for="email">Pasirinkite Mėnesį</label>
  <CheckBoxAreaForInvoicesComponent bind:selectedMonths={selectedMonths}></CheckBoxAreaForInvoicesComponent>

  <button type="submit"><span>{isCreatingPDF ? 'Kuriama...' : 'Sukurti Sąskaitą'}</span></button>
</form>
{/if}

{#if pdfUrl} <!-- Show PDF in iframe if URL exists -->
  <div>
      <iframe title="pdf_preview" src={pdfUrl} width="100%" height="600px"></iframe>
  </div>
 
  
  <div>
    <label for="email">Įveskite el. pašto adresą:</label>
    <input id="email" type="email" bind:value={email} placeholder="El. pašto adresas" required />
  </div>
  <button type="button" on:click={() => sendEmail(email)} disabled={isSending}>
      <span>{buttonText}</span>
  </button>
{/if}
