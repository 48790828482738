<script>
    import { onMount } from 'svelte';
    import { link } from 'svelte-spa-router';
    import StudentName from './StudentNameComponent.svelte'
    import ClassName from './ClassNameComponent.svelte';
    import TeacherName from './TeacherNameComponent.svelte';
    import TimeShower from './TimeShowerComponent.svelte';
    import ButtonToJoinClass from './ButtonToJoinClassComponent.svelte';
    import FilterButtons from './FilterButtonsForReschRequestsComponent.svelte';
    import ButtonToComment from './ButtonToLeaveCommentComponent.svelte';
    import RescheduleButton from './ButtonToRescheduleComponent.svelte';
    import StudentNameComponent from './StudentNameComponent.svelte';
  
    let events = [];
    let currentFilter = 'all';
    let isUserTeacher = false;
    

    function getClassForEvent(event) {
        // Determine the border color based on the logic provided
        if (event.status === 'Accepted') {
            return 'green-border';
        }
        if (event.status === 'Denied'){
            return 'red-border';
        }
        if ((event.directedTo === 'Teacher' && isUserTeacher) || (event.directedTo === 'Student' && !isUserTeacher)) {
            return 'yellow-border';
        }
        if ((event.directedTo === 'Teacher' && !isUserTeacher) || (event.directedTo === 'Student' && isUserTeacher)) {
            return 'grey-border';
        }
            return ''; // Default case, if needed
    }

    function getHeaderText(event) {
        if (event.status === 'Accepted') {
            return 'PATVIRTINTA';
        }
        if (event.status === 'Denied') {
            return 'NEPATVIRTINTA';
        }
        if ((event.directedTo === 'Teacher' && isUserTeacher) || (event.directedTo === 'Student' && !isUserTeacher)) {
            return 'REIKIA ATSAKYTI';
        }
        if ((event.directedTo === 'Teacher' && !isUserTeacher) || (event.directedTo === 'Student' && isUserTeacher)) {
            return 'LAUKIAMA ATSAKYMO';
        }
        return ''; // Default case, if needed
    }
    // Function to change the link text when clicked
    function changeLinkText(event) {
      const link = event.target;
      link.textContent = "Reschedule request sent!";
    }
    const formatDateTime = (datetime) => {
        let parts = datetime.split(" ");
        let datePart = parts[0];
        let timePart = parts[1];
        console.log(parts)
        console.log(datePart)
        console.log(timePart)
        
        

       
        return { timePart, datePart };
    };
    function isPastEvent(eventTime) {
      const eventDateTime = new Date(eventTime);
      const currentDateTime = new Date();
      return eventDateTime < currentDateTime;
    }
    
    // Function to handle confirm/deny button click
  const handleAction = async (event, action) => {
    const token = localStorage.getItem('jwtToken');

    try {
      const response = await fetch(`https://www.tutory.app/teacher_playground/rescheduleReq/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          request_id: event.idGcal,
          action: action
        })
      });

      if (response.ok) {
        // Refresh the events after action is performed
        fetchEvents();
      } else {
        console.error('Failed to perform action:', response.statusText);
      }
    } catch (error) {
      console.error('Error in action:', error);
    }
  };

    const fetchEvents = async () => {
      const token = localStorage.getItem('jwtToken'); // Retrieve token from localStorage
  
      if (!token) {
        console.error('No JWT token found. User might not be logged in.');
        return;
      }
  
      try {
        // Determine the URL
        const url = `https://www.tutory.app/teacher_playground/rescheduleReq/?filter=${currentFilter}`;
  
        console.log("Fetching events from:", url);
  
        // Make the request
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
  
        if (response.ok) {
          const data = await response.json();
          events = data.events;
          isUserTeacher = data.isUserTeacher
          console.log("These are the mf eventicus")
          console.log(events)
          console.log(isUserTeacher)
        } else {
            if (response.status === 401) {
                // If response is 401 Unauthorized, redirect to login
                alert('Reikia prisijungti iš naujo.');
                localStorage.removeItem('jwtToken'); // Optional: Clear any stored tokens
                window.location.href = '#/about'; // Redirect to login page
            } else {
                // Handle other errors
                alert('Error submitting form!');
            }
        }
      } catch (error) {
        console.error("Failed to fetch events:", error);
      }
      
    };
  
    // Handle filter change event from FilterButtons component
    const handleFilterChange = (event) => {
      currentFilter = event.detail.filter; // Update the filter
      fetchEvents(); // Fetch new events based on the filter
    };
  
    // Fetch events when the component is mounted
    onMount(() => {
      fetchEvents();
    });
    function getHeaderClass(event) {
      if (event.status === 'Accepted') {
          return 'green-header';
      }
      if (event.status === 'Denied') {
          return 'red-header';
      }
      if ((event.directedTo === 'Teacher' && isUserTeacher) || (event.directedTo === 'Student' && !isUserTeacher)) {
          return 'yellow-header';
      }
      if ((event.directedTo === 'Teacher' && !isUserTeacher) || (event.directedTo === 'Student' && isUserTeacher)) {
          return 'grey-header';
      }
      return ''; // Default case, if needed
  }
  </script>
  
  <style>
    .red-border {
        border: 2px solid #b3261e;
    }

    .yellow-border {
        border: 2px solid #e8b931;
    }

    .green-border {
        border: 2px solid #14695b;
    }
    .grey-border {
        border: 2px solid #7e8f8c;
    }

    .header {
        display: flex; /* Enables flexbox */
        justify-content: center; /* Centers content horizontally */
        align-items: center; /* Centers content vertically */
        padding: 10px 0; /* Only top and bottom padding, no left or right */
        font-size: larger;
        font-weight: bold;
        width: calc(100% + 30px); /* Compensate for negative margins */
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        box-sizing: border-box;
        margin: -15px -15px 0 -15px;
    }

    .red-header {
        background-color: #b3261e;
        color: white;
    }

    .yellow-header {
        background-color: #e8b931;
        color: white;
    }

    .green-header {
        background-color: #14695b;
        color: white;
    }
    .grey-header{
        background-color: #7e8f8c;
        color: white;
    }
    .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: transparent;
      border-bottom: 1px solid #000000;
      margin-bottom: 40px;
    }
  
    .filler {
      width: 50px;
    }
  
    .label {
      font-size: x-large;
    }
  
    .icon {
      width: 50px;
      height: 50px;
    }
    /* Button styling */
    button span {
        font-weight: bolder;
        font-size:larger;
    }

    button {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 20px;
        gap: 10px;
    
        width: 100%;
        height: 60px;
    
        /* Purple background */
        background-color: white;
    
        /* Black border */
        border: 2px solid #000000;
    
        /* Box shadow */
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    
        /* Rounded corners */
        border-radius: 8px;
    
        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    
        /* Remove default button styling */
        outline: none;
        cursor: pointer;
  
        /* Remove the default button background on focus */
        background-image: none;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    /* Button hover effect */
    button:hover {
        background-color: #14695b;
        color:white;
    }

    /* Text inside the button */
    span {
        font-size: 1rem;
        font-weight: bold;
        
    }
    .classcontainer {
      padding: 15px;
      background-color: white;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      gap: 10px;
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
      border-radius: 8px;
      color: black;
      align-items: flex-start;
    }
  
    
    .senasLaikas{
        color:red;
    }
    .naujasLaikas{
        color:green;
    }
    .addi_text{
        font-weight:lighter;
    }
  </style>
  
  <div class="top-bar">
    <a href='#/login-greeting' class="exit-button">
      <img src='/static/icons/backButtonIcon.png' alt="Exit" class="icon" />
    </a>
    <div class="label">Pamokų Pakeitimai</div>
    <div class="filler"></div>
  </div>
  
  <FilterButtons 
    currentFilter={currentFilter} 
    on:filterChange={handleFilterChange} />
  
  <div class="classSection">
    {#each events as event}
      <div class="classcontainer {getClassForEvent(event)}">
        <!-- Dynamic header based on status -->
        <div class="header {getHeaderClass(event)}">
          {getHeaderText(event)}
        </div>
  
        <!-- Render the reschedule request details -->
        <div>
          <ClassName name={event.clName} />
          <StudentName student={event.studName}/>
          <TeacherName teacher={event.teacher} />
          <div class='senasLaikas'>
            <strong>Senas laikas:</strong>
            <TimeShower 
              time={formatDateTime(event.oldDisplay).timePart} 
              date={formatDateTime(event.oldDisplay).datePart} 
            />
          </div>
          <div class='naujasLaikas'>
            <strong>Naujas laikas:</strong>
            <TimeShower 
              time={formatDateTime(event.newDisplay).timePart} 
              date={formatDateTime(event.newDisplay).datePart} 
            />
          </div>
          <!-- <strong>Status:</strong> {event.status} <br>
          <strong>Type:</strong> {event.type}<br>
          <strong>Directed to:</strong>{event.directedTo} -->
        </div>
        {#if event.status ==='Pending' && getHeaderClass(event) ==='grey-header'}
            <div class="addi_text">
                Laukiama atsakymo iš pamokos dalyvių
            </div>
        {/if}
        {#if event.status === 'Pending' && getHeaderClass(event) !=="grey-header"}
          <button on:click={() => handleAction(event, 'confirm')}><span>Patvirtinti</span></button>
          <button on:click={() => handleAction(event, 'deny')}><span>Atmesti</span></button>

            
        {:else}
          <RescheduleButton classId={event.idGcal} label="Pasiūlyti kitą laiką" />
        {/if}
      </div>
    {/each}
  </div>