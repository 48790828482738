<script>
    import { createEventDispatcher } from 'svelte';
  
    // Initialize the dispatcher for sending events to the parent
    const dispatch = createEventDispatcher();
    let reason = '';

    // Function to handle submit
    function handleSubmit() {
      // Emit the selected datetime to the parent
      dispatch('submit', { reason });
    }
  </script>
  
  <!-- Component markup -->
  <div>
    <textarea bind:value={reason} placeholder="Įrašykite atšaukimo priežastį..." rows="4"></textarea>
    <button on:click={handleSubmit}>
      <span>Patvirtinti</span>
    </button>
  </div>
<style>
  div{
    width:100%;
  }
  textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #000000;
    
    /* Box shadow */
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    
    /* Rounded corners */
    border-radius: 8px;

    /* Adjust the padding for better text visibility */
    padding: 10px;
    
    /* Optional: Set a font for better readability */
    font-family: Arial, sans-serif;
    font-size: 16px;
    resize: vertical; /* Allows the user to resize the textarea vertically */
}

  button span {
        font-weight: bolder;
        font-size:larger;
    }

  button {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 20px;
        gap: 10px;
    
        width: 100%;
        height: 60px;
    
        /* Purple background */
        background-color: #baccff;
    
        /* Black border */
        border: 1px solid #000000;
    
        /* Box shadow */
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    
        /* Rounded corners */
        border-radius: 8px;
    
        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    
        /* Remove default button styling */
        outline: none;
        cursor: pointer;
  
        /* Remove the default button background on focus */
        background-image: none;
    }

    /* Button hover effect */
    button:hover {
        background-color: #a0b3e1;
    }

    /* Text inside the button */
    span {
        font-size: 1rem;
        font-weight: bold;
        color: #333;
    }
</style>