
<script>
    import ClassNameComponent from './ClassNameComponent.svelte';
    import TimeShowerComponentForTeacherReq from './TimeShowerComponentForTeacherReq.svelte';
    export let events = []
    let errorMessage = ''
    import { onMount } from 'svelte';
    async function retrieveEventData() {
        console.log("ive at least made it this far222")
        try {
            // Retrieve JWT token from localStorage/sessionStorage/cookie (depending on how you store it)
            const token = localStorage.getItem('jwtToken'); 
            console.log("THIS IS THE TOKEN222")
            console.log(token)
            if (!token) {
                throw new Error('JWT token not found. Please log in.');
            }

            const response = await fetch('https://www.tutory.app/studentplayground/teacher_requests_list/', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                // Handle error response from the server
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to check teacher status');
            }

            const data = await response.json();
            events = data.requests;
            console.log("This is the value of request222")
            console.log(events)
            
        } catch (error) {
            errorMessage = error.message;
        }
    }
    async function accept_request(event_id) {
        console.log("ive at least made it this far333")
        try {
            // Retrieve JWT token from localStorage/sessionStorage/cookie (depending on how you store it)
            const token = localStorage.getItem('jwtToken'); 
            console.log("THIS IS THE TOKEN3333")
            console.log(token)
            if (!token) {
                throw new Error('JWT token not found. Please log in.');
            }
            const payload = {event_id:event_id}
            console.log("This is payload")
            console.log(payload)
            const response = await fetch('https://www.tutory.app/studentplayground/accept_request_api/', {
                method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                    },
                    body: JSON.stringify(payload)
                });
            console.log("we are past sending api call")
            if (!response.ok) {
                // Handle error response from the server
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to accept teacher request');
            }

            const data = await response.json();
            console.log("awaited for the response hell yeah")
            retrieveEventData()
        } catch (error) {
            errorMessage = error.message;
        }
    }
    onMount(() => {
        console.log("THIS IS THE VERY FIRST STEP2222")
        retrieveEventData();
    });
    
    function getHeaderClass(event) {
      if (event.accepted_by === 'null') {
          return 'blue-header';
      }
      else {
          return 'red-header';
      }
    }
    function getHeaderText(event) {
        if (event.accepted_by === 'null') {
            return 'LAUKIAMA MOKYTOJO';
        }
        else{
            return 'MOKOMA';
        }
    }
    function getClassForEvent(event) {
        // Determine the border color based on the logic provided
        if (event.accepted_by === 'null') {
            return 'blue-border';
        }
        else{
            return 'red-border';
        }
        
    }

</script>
<div class="top-bar">
    <a href='#/login-greeting' class="exit-button">
      <img src='/static/icons/backButtonIcon.png' alt="Exit" class="icon" />
    </a>
    <div class="label">Pamokų Pasiūlymai</div>
    <div class="filler"></div>
  </div>
{#each events as event}
    <div class="classcontainer {getClassForEvent(event)}">
        <div class="header {getHeaderClass(event)}">
            {getHeaderText(event)}
          </div>
        <ClassNameComponent name={event.class_name}></ClassNameComponent>
        <span><span class='make_bold'>Mokiniai: </span>{event.student_name}</span>
        {#each event.timeslots as ts}
            <TimeShowerComponentForTeacherReq time={ts[1] } date={ts[0]} ></TimeShowerComponentForTeacherReq>
            
        {/each}
        <span><span class='make_bold'>Klasė: </span>{event.grade}</span>
        <span class='make_bold'>Aprašymas:</span>
        <div class="noteDiv">
            {event.note}
        </div>
        {#if event.accepted_by === 'null'}
        <button on:click={() => accept_request(event.id)}>
            <span>Priimti</span>
        </button>
        {/if}
    </div>




{/each}

<style>
    .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: transparent;
      border-bottom: 1px solid #000000;
      margin-bottom: 40px;
    }
  
    .filler {
      width: 50px;
    }
  
    .label {
      font-size: x-large;
    }
  
    .icon {
      width: 50px;
      height: 50px;
    }
    .make_bold{
        font-weight: bold;
    }
    .noteDiv {
        background-color: transparent;
        width: 100%;
        border-radius: 6px;
        display: flex;
        justify-content: space-around;
        padding: 10px; /* Add padding to move the content */
        box-sizing: border-box;
        border: 2px solid #baccff;
    }
    .classcontainer{
        padding: 15px;
        background-color: white;
        display: flex;
        flex-direction: column;
        margin-bottom:15px;
        gap: 10px;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        flex: none;
        color:black;
        align-items: flex-start;
      }
    .red-border {
        border: 2px solid #7e8f8c;
    }
    .blue-border {
        border: 2px solid #e8b931;
    }
    .header {
        display: flex; /* Enables flexbox */
        justify-content: center; /* Centers content horizontally */
        align-items: center; /* Centers content vertically */
        padding: 10px 0; /* Only top and bottom padding, no left or right */
        font-size: larger;
        font-weight: bold;
        width: calc(100% + 30px); /* Compensate for negative margins */
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        box-sizing: border-box;
        margin: -15px -15px 0 -15px;
    }
    .red-header {
        background-color: #7e8f8c;
        color: white;
    }
    .blue-header {
        background-color: #e8b931;
        color: black;
    }
     /* Button styling */
     button span {
        font-weight: bolder;
        font-size:larger;
    }

    button {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 20px;
        gap: 10px;
    
        width: 100%;
        height: 60px;
    
        /* Purple background */
        background-color: white;
    
        /* Black border */
        border: 2px solid #000000;
    
        /* Box shadow */
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    
        /* Rounded corners */
        border-radius: 8px;
    
        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    
        /* Remove default button styling */
        outline: none;
        cursor: pointer;
  
        /* Remove the default button background on focus */
        background-image: none;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    /* Button hover effect */
    button:hover {
        background-color: #e8b931;
        color:black;
    }

    /* Text inside the button */
</style>