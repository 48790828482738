<script>
    import { onMount } from 'svelte';
    import { writable } from 'svelte/store';

    // Stores for form data
    let selectedStudent = writable("");
    let students = writable([]);
    let comments = writable(null);

    // Fetch list of students when component mounts
    onMount(async () => {
        try {
            const token = localStorage.getItem('jwtToken');
            const response = await fetch('https://www.tutory.app/studentplayground/get_all_students/', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                students.set(data.students);  // Set students data with first_name and last_name
            } else {
                console.error("Failed to load students");
            }
        } catch (error) {
            console.error("Error fetching students:", error);
        }
    });

    // Function to fetch comments for the selected student
    async function getComments() {
        if (!$selectedStudent) return;

        const payload = { student: $selectedStudent };  // Use selected student's ID

        try {
            const token = localStorage.getItem('jwtToken');
            const response = await fetch('https://www.tutory.app/studentplayground/get_comments_admin', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                comments.set(data.comments); // Set comments data
            } else {
                console.error("Error fetching comments");
                comments.set([{ error: "No comments found for this student" }]);
            }
        } catch (error) {
            console.error("Request failed:", error);
            comments.set([{ error: "Request failed" }]);
        }
    }
</script>

<!-- UI for selecting the student and fetching comments -->
<div class="container">
    <h2>Student Comments Viewer</h2>

    <!-- Dropdown for selecting a student -->
    <div class="form-group">
        <label for="student">Select Student:</label>
        <select id="student" bind:value={$selectedStudent}>
            <option value="" disabled selected>Select a student</option>
            {#each $students as student}
                <option value={student.id}>{student.first_name} {student.last_name}</option> <!-- Display names -->
            {/each}
        </select>
    </div>

    <!-- Fetch Comments Button -->
    <button on:click={getComments}>Get Comments</button>

    <!-- Display Comments -->
    <div class="display-box">
        {#if $comments}
            {#if $comments[0]?.error}
                <p class="error">Error: {$comments[0].error}</p>
            {:else}
                {#each $comments as comment}
                    <pre>{JSON.stringify(comment, null, 2)}</pre>
                {/each}
            {/if}
        {:else}
            <p>No data yet. Click the button to load comments.</p>
        {/if}
    </div>
</div>
